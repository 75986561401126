
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        














































































.head {
  border-radius: rem(8);

  .is-content-visible & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.body {
  display: none;
  background: radial-gradient(ellipse at 50% 100%, #efeff5, transparent)
    $body-bg;

  .is-content-visible & {
    display: block;
  }
}

.day {
  font-size: rem(36);
  line-height: 1;
}
